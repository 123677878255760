import React, { Component } from "react";
import { gettext } from "django-i18n";
import { noop } from "lodash";
import moment from "moment";

import Divider from "@deprecated/material-ui/Divider";
import MenuItem from "@deprecated/material-ui/MenuItem";

import DatePicker from "./DatePicker";
import SelectField from "./SelectField";

/**
 * Field component that can select a date range.
 * Can be used as a single value or two value control.
 *
 * Single value is in ISO 8601 date range format: YYYY-MM-DD/YYYY-MM-DD
 */
class DateRangePicker extends Component {
  constructor(props) {
    super(props);

    this.initialState = {
      custom: false,
      endValue: "",
      startValue: "",
      value: "",
    };

    this.state = this.initialState;
  }

  componentDidMount() {
    const { endValue, startValue, value } = this.props;

    const newState = {
      endValue,
      startValue,
    };

    this.setDateRange(value ? this.parse(value) : newState, false);
  }

  setDateRange(data, notify = true) {
    const { endValue, startValue } = this.state;

    if (startValue === data.startValue && endValue === data.endValue) {
      return;
    }

    const newState = {
      ...this.state,
      endValue: data.endValue,
      startValue: data.startValue,
      value: `${data.startValue}/${data.endValue}`,
    };

    if (data.custom !== undefined && data.custom !== null) {
      newState.custom = data.custom;
    }

    this.setState(newState);

    if (notify) {
      const { onChange, onEndChange, onStartChange } = this.props;

      onChange && onChange(newState.value);

      if (startValue !== newState.startValue) {
        onStartChange && onStartChange(newState.startValue);
      }

      if (endValue !== newState.endValue) {
        onEndChange && onEndChange(newState.endValue);
      }
    }
  }

  handleOnChange(name, value) {
    const state = { ...this.state };
    state[`${name}Value`] = value;

    this.setDateRange(state);
  }

  handleQuickPickChange(event, index, value) {
    if (value === "custom") {
      this.setState({ custom: true });
    } else {
      const newState = this.parse(value);
      newState.custom = false;
      this.setDateRange(newState);
    }
  }

  parse(str) {
    const [startValue, endValue] = str.split("/");

    if (!startValue || !endValue) {
      return this.initialState;
    }

    return { startValue, endValue };
  }

  // Takes two methods start & end which are called with the moment/date object as input
  // Returns a concatenated string of both dates in the correct format
  range(start = (m) => m, end = (m) => m) {
    const startValue = start(moment()).format("YYYY-MM-DD");
    const endValue = end(moment()).format("YYYY-MM-DD");
    return `${startValue}/${endValue}`;
  }

  render() {
    const { endLabel, startLabel, untilToday } = this.props;
    const { custom, endValue, startValue, value } = this.state;
    const datePickerStyle = {
      display: "table-cell",
    };
    const separatorStyle = {
      fontWeight: 300,
      paddingLeft: 5,
      paddingRight: 5,
    };

    const baseOptions = [
      <MenuItem
        key="last-7-days"
        primaryText={gettext("Last 7 days")}
        value={this.range(
          (m) => m.subtract(6, "days"),
          (m) => m
        )}
      />,
      <MenuItem
        key="last-30-days"
        primaryText={gettext("Last 30 days")}
        value={this.range(
          (m) => m.subtract(29, "days"),
          (m) => m
        )}
      />,
      <MenuItem
        key="last-3-months"
        primaryText={gettext("Last 3 months")}
        value={this.range(
          (m) => m.subtract(3, "months").add(1, "days"),
          (m) => m
        )}
      />,
      <MenuItem
        key="last-12-months"
        primaryText={gettext("Last 12 months")}
        value={this.range(
          (m) => m.subtract(12, "months").add(1, "days"),
          (m) => m
        )}
      />,
    ];

    const extraOptions = [
      <Divider key="extra-divider-1" />,
      <MenuItem
        key="this-month"
        primaryText={gettext("This Month")}
        value={this.range(
          (m) => m.startOf("month"),
          (m) => m.endOf("month")
        )}
      />,
      <MenuItem
        key="last-month"
        primaryText={gettext("Last Month")}
        value={this.range(
          (m) => m.subtract(1, "month").startOf("month"),
          (m) => m.subtract(1, "month").endOf("month")
        )}
      />,
      <Divider key="extra-divider-2" />,
      <MenuItem
        key="this-year"
        primaryText={gettext("This Year")}
        value={this.range(
          (m) => m.startOf("year"),
          (m) => m.endOf("year")
        )}
      />,
      <MenuItem
        key="last-year"
        primaryText={gettext("Last Year")}
        value={this.range(
          (m) => m.subtract(1, "year").startOf("year"),
          (m) => m.subtract(1, "year").endOf("year")
        )}
      />,
    ];

    const customRange = [
      <Divider key="custom-divider" />,
      <MenuItem key="custom" primaryText={gettext("Custom")} value="custom" />,
    ];

    return (
      <div>
        <SelectField
          value={custom ? "custom" : value}
          onChange={this.handleQuickPickChange.bind(this)}
          floatingLabelText={this.props.floatingLabelText}
        >
          {untilToday
            ? baseOptions.concat(customRange)
            : baseOptions.concat(extraOptions, customRange)}
        </SelectField>

        {custom && (
          <div>
            <div style={datePickerStyle}>
              <DatePicker
                floatingLabelText={startLabel}
                onChange={this.handleOnChange.bind(this, "start")}
                value={startValue}
                maxDate={endValue}
                clearable={false}
              />
            </div>
            <div style={datePickerStyle}>
              <span style={separatorStyle}>-</span>
            </div>
            <div style={datePickerStyle}>
              <DatePicker
                floatingLabelText={endLabel}
                disabled={untilToday}
                onChange={this.handleOnChange.bind(this, "end")}
                value={endValue}
                minDate={startValue}
                clearable={false}
              />
            </div>
          </div>
        )}

        <input type="hidden" name={this.props.name} value={value} />
      </div>
    );
  }
}

DateRangePicker.defaultProps = {
  endLabel: gettext("To"),
  endValue: "",

  startLabel: gettext("From"),
  startValue: "",

  value: null,

  onChange: noop,
  onEndChange: noop,
  onStartChange: noop,
};

export default DateRangePicker;
