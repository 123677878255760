import { gettext } from "django-i18n";
import React from "react";
import PropTypes from "prop-types";

import MenuItem from "@deprecated/material-ui/MenuItem";

import SelectField from "../SelectField";

/**
 * Select field widget for selecting Global Roles.
 */
class GlobalRoles extends React.Component {
  renderDescription(roleID) {
    const { suggestions } = this.props;
    const role = suggestions.find((role) => role.id === roleID);

    return (role && role.description) || null;
  }

  render() {
    const {
      floatingLabelText,
      hintText,
      name,
      onBlur,
      onChange,
      suggestions,
      value,
    } = this.props;

    const items = suggestions.map((suggestion) => (
      <MenuItem
        key={suggestion.id}
        value={suggestion.id}
        primaryText={suggestion.name}
      />
    ));

    return (
      <div>
        <SelectField
          floatingLabelText={floatingLabelText}
          hintText={hintText}
          maxHeight={192}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          value={value}
        >
          {items}
        </SelectField>
        <div className="sde-group-subtitle">
          {this.renderDescription(value)}
        </div>
      </div>
    );
  }
}

GlobalRoles.propTypes = {
  floatingLabelText: PropTypes.string,
  suggestions: PropTypes.array.isRequired,
};

GlobalRoles.defaultProps = {
  floatingLabelText: gettext("Global Role"),
  hintText: gettext("Search for a role..."),
};

export default GlobalRoles;
